import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import OpenVacancy from "../components/OpenVacancyPage"

export const RubyPageTemplate = ({ title, t }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <OpenVacancy
        title={t.title}
        type={t.type}
        link={t.link}
        description={t.description}
        tags={t.tags}
        education={t.education}
        technicalSkills={t.technicalSkills}
        personalSkills={t.personalSkills}
        duties={t.duties}
        offers={t.offers}
        salary={t.salary}
        experience={t.experience}
        location={t.location}
        previousPosition={t.previousPosition}
        previousLink="vacancies/nodejs"
        nextPosition={t.nextPosition}
        nextLink="vacancies/nodejs"
        previous={t.previous}
        next={t.next}
      />
    </>
  )
}

RubyPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
}

const RubyPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout location={location}>
      <RubyPageTemplate title={post.frontmatter.title} t={post.frontmatter} />
    </Layout>
  )
}

RubyPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default RubyPage

export const RubyPageQuery = graphql`
  query RubyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        type
        link
        description
        tags
        education
        technicalSkills
        personalSkills
        duties
        offers
        salary
        experience
        location
        previousPosition
        nextPosition
        previous
        next
      }
    }
  }
`
